@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Mulish:wght@200;300;400;500;600;700;900&family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&family=Poppins:wght@200;300;400;600&family=Roboto:ital,wght@0,400;0,700;1,300;1,400;1,700&family=Teko:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Mulish:wght@200;300;400;500;600;700;900&family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&family=Poppins:wght@200;300;400;600&family=Roboto:ital,wght@0,400;0,700;1,300;1,400;1,700&family=Teko:wght@300;400;500;600;700&display=swap);
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Plus Jakarta Sans';
}
:root{
    --buttom_color:#bf73f3;
    --button_hover_color:#cc8ff5;
    --focus_color:#a061cc;
    --primery_disable_btn:#e7cafa;
    --secondary_btn-color:#0f5238;
    --sec_hover_color:#0a3826;
    --sec_focus_color:#1d5d45;
    --error_color:#b00020;
    -o-border_color:#bbefe5;
}
body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.next {
    font-size: 14px;
    font-weight: 600;
    color: #b5c9d2;
}
.preview {
    font-size: 14px;
    font-weight: 600;
    color: #0f5238;
    color: var(--secondary_btn-color);
     border-color: #0f5238;
     border-color: var(--secondary_btn-color);
}
button.next {
    border-radius: 20px;
}
button.preview {
    border-radius: 20px;
}
button.preview {
    border: 2px solid #0f5238;
    border: 2px solid var(--secondary_btn-color);
    color: #0f5238;
    color: var(--secondary_btn-color);
}
.logo {
    padding-top: 30px;
}
span.subtitle {
    color: #ff00ea;
    font-size: 26px;
}
ul.welcome-space li {
    margin-bottom: 0.5rem;
}
button {
    padding: 0.5rem 1rem;
    background-color: transparent;
    margin: 0.5rem 0;
}
.fotter-btn {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.fotter-btn.btn-right {
    margin-left: auto;
    display: table;
}
.imageCover {
    max-width: 112px;
    height: auto;
    margin-bottom: 0;
    margin: 10px auto 40px;
    display: block;
}
p.head {
    font-size: 15px color;
    color: #a7a09a;
    font-size: 15px;
    margin-bottom: 10px;
    margin-top: 29px;
}
input,
select {
    text-align: left;
    padding: 0.3rem 0.4rem;
    margin: 0.5rem 0 0.5rem 0.8rem;
    border: 1px solid #a4a4a4;
    color: #a2a2a2;
    width: 50%;
}
.form {
    max-width: 700px;
    margin: auto;
    width: 100%;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 34px;
    margin-bottom: 34px;
    background: #ffff;
    border-radius: 4px;
    padding: 0;
    position: relative;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    transition: 0.3s;
}
.form:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
input,
select,
textarea {
    text-align: left;
    padding: 0.7rem 0.8rem;
    margin: 9px 0;
    color: #76787e;
    display: block;
    background: #fff;
    border: none;
    width: 50%;
    margin-bottom: 40px;
    border-radius: 5px;
    font-weight: 400;
}
.fullinput input {
    width: 100%;
}
h3 {
    font-size: 25px;
    color: #000;
    font-family: auto;
}
h6 {
    font-size: 14px;
}
label {
    color: #a7a09a;
    font-size: 15px;
    font-weight: 600;
}
.form.print {
    padding: 25px;
}
.form.print div {
    font-size: 15px;
    color: #343434;
}
.form.print button {
    float: right;
    border: 2px solid #c7b0a0;
    width: 82px;
    text-align: center;
    font-size: 14px;
}
::marker {
    color:#0f5238;
    color:var(--secondary_btn-color);
}
.rightarrow {
    position: relative;
    top: -1px;
    left: 8px;
}
.preview {
    font-size: 14px;
    font-weight: 600;
    color: #0f5238;
    color: var(--secondary_btn-color);
}
.login {
    width: 410px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding: 0 55px 63px 55px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    margin: 0 auto;
}
input[type="text"] {
    padding-left: 7px;
    font-size: 15px;
}
:focus {
    outline: -webkit-focus-ring-color auto 0;
}
.login label {
    font-weight: 400;
}
input.button-login {
    display: block;
    position: relative;
    z-index: 1;
    border-radius: 25px;
    overflow: hidden;
    margin: 0 auto;
    background: #ff00ea;
    text-align: center;
    width: 252px;
    margin-bottom: 32px;
    color: #fff;
}
.new-attach-info {
    margin: 15px auto;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}
.ATTACH {
    width: 200px;
    position: relative;
    margin: auto;
}
input#bank_document {
    background: #ffff;
}
label#Border {
    width: 88px;
    border-radius: 20px;
    color: #fff;
}
label#borderRight {
    border-radius: 20px;
    width: 88px;
    position: relative;
    right: 27px;
    color: #fff;
}
.btn-name {
    width: 100%;
    height: 30px;
    cursor: pointer;
}
a {
    text-decoration: none;
}
.button-lg a {
    text-align: justify;
    color: #343534;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 16px;
    margin: 0 auto;
    display: block;
    position: relative;
    left: 40%;
    word-spacing: 2px;
}
#step2 hr {
    width: 282px;
    margin: 15px auto 30px;
    border-color: #f0f4f5;
}
button {
    cursor: pointer;
}
button:hover {
    background: #7cbabf;
    color: #fff;
}
.btn-name:hover .fas {
    background: #ff00ea;
}
.greytext {
    color: #696969;
    font-weight: 200;
    line-height: 27px;
    margin-top: 30px;
    margin-bottom: 40px;
}
.second h6 {
    margin-bottom: 6px;
    margin-top: 0;
}
.grid-container {
    display: grid;
    grid-template-columns: 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px 20px;
}
.grid-item {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 4px;
    text-align: center;
    margin-bottom: 22px;
    color: #000;
    background-color: #ff00ea;
}
.form.print button.submit {
    float: none;
    margin: 50px auto;
    display: block;
}
img.thumb {
    width: 100%;
    max-width: 36%;
}
.error {
    color: #b00020 !important;
    color: var(--error_color) !important;
    position: relative;
    bottom: 22px;
    font-size: 13px;
    font-weight: 600;
    display: block;
}
select {
    height: 3rem;
}
button {
    width: 118px;
}
input,
textarea {
    margin-bottom: 30px;
}
.list_style {
    list-style: none;
    margin-bottom: 10px;
}
select {
    margin-bottom: 30px;
}
label {
    display: block;
    margin-bottom: 0.5rem;
}
.form h6 {
    margin-bottom: 32px;
    margin-top: 6px;
    font-weight: 600;
}
.form h3 {
    font-weight: 600;
    margin-bottom: 25px;
    font-size: 25px;
}
button {
    transition: 0.9s;
}
button:hover {
    background: #ff00ea;
    color: #fff;
}
.leftarrow {
    position: relative;
    top: -1px;
    right: 8px;
}
.fullinput input {
    width: 100%;
}
.second h6 {
    margin-bottom: 13px;
}
.button_group .btn-secondary {
    border-radius: 0;
    border: none;
    width: 62px;
    background: #999a99;
}
.button_group .btn-primary {
    border-radius: 0;
    width: 62px;
    background: #545b62;
    border: none;
}
.form .btn-primary:hover {
    color: #fff;
    background-color: #927562;
    border-color: #b69783;
}
button.toogles.active {
    border: 1px solid #000;
}
.button_group {
    display: table;
    margin-left: auto;
}
.fullinput.same {
    margin-top: 27px;
    margin-bottom: 11px;
}
.fullinput.same label {
    color: #282829;
    font-weight: 600;
}
.preview {
    border: 2px solid #ff00ea;
}
.preview:hover {
    background: #ff00ea;
    border: #ff00ea;
    color: #fff;
}
.button_group.toogleset {
    position: relative;
    bottom: 43px;
}
.form.print h4 {
    margin: 23px 0;
}
label.btn-1 {
    position: absolute;
    bottom: 16px;
    cursor: pointer;
}
.ui.selection.dropdown {
    cursor: pointer;
    word-wrap: break-word;
    line-height: 1em;
    white-space: normal;
    outline: 0;
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
    min-width: 14em;
    min-height: 2.71428571em;
    background: #fff;
    display: inline-block;
    padding: 0.78571429em 2.1em 0.78571429em 1em;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: none;
    border: none;
    border-radius: 0;
    transition: box-shadow 0.1s ease, width 0.1s ease;
    margin-bottom: 18px;
}
.selectbox .ui.search.dropdown > input.search {
    background: none #f9f9f9;
    cursor: pointer;
}
.selectbox .ui.selection.dropdown {
    border: none;
}
.selectbox .col-6 {
    padding: 0;
}
.fullinput select {
    width: 100%;
    height: 42px;
}
.form .react-tel-input .form-control {
    font-size: 14px;
    letter-spacing: 0.01rem;
    margin-top: 7px;
    margin-bottom: 0;
    padding-left: 45px;
    margin-left: 0;
    background: #ffff;
    border: none;
    border-radius: 5px;
    line-height: 21px;
    height: 40px;
    width: 93%;
    outline: 0;
    padding-right: 0;
}
.form .ui.search.dropdown > input.search {
    background: none #f9f9f9;
}
.ui.selection.dropdown {
    border: none;
}
.takepic {
    margin: 0 auto;
    display: table;
}
.takepics {
    display: -webkit-flex;
    display: flex;
}
.take-a-photo {
    margin-left: 32px;
}
.takepic {
    border: none;
    width: auto;
    color: #a7a09a;
    font-size: 15px;
    font-weight: 600;
}
.usual li {
    padding-bottom: 18px;
    list-style: none;
}
.usual.space li {
    padding-bottom: 0;
}
.col {
    position: relative;
    top: 30px;
}
label.containers_2 {
    position: relative;
    bottom: 25px;
}
.containers {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
}
.containers input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #939394;
}
.setSelfieImage {
    margin: 0 AUTO;
    display: BLOCK;
}
.containers:hover input ~ .checkmark {
    background-color: #0a3826;
    background-color: var(--sec_hover_color);
}
.containers input:checked ~ .checkmark {
    background-color: #0f5238;
    background-color: var(--secondary_btn-color);
}
.checkmark:after {
    content: "";
    position: absolute;
}
.containers input:checked ~ .checkmark:after {
    display: block;
}
.containers .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.checkbox-my label {
    font-size: 14px;
    color: #212529;
    font-weight: 600;
    margin-bottom: 27px;
}
.checkbox-my {
    margin-left: 12px;
}
.checkbox-my.left {
    margin-left: 0;
}
.full {
    width: 100%;
}
.copybank {
    position: relative;
    top: 33px;
}
.download {

    width: 28px;
    margin-bottom: 31px;
}
.usual.back-color {
    background: #f9f9f9;
    margin-bottom: 24px;
}
button.ok {
    display: block;
    margin: 0 auto 24px;
}
button.next.setwidht {
    width: 192px;
}
.fullspace.pandingfinsh {
    width: 100%;
}
.fullspace {
    width: 100%;
}
.form .ui.search.dropdown > input.search {
    background: none #f9f9f9;
    padding: 0;
    height: 42px;
    top: 0;
    margin: 3px 0 0;
}
.ui.search.dropdown > .text {
    top: 5px;
}
.ui.fluid.dropdown > .dropdown.icon {
    top: 14px;
}
.button_group.pos {
    position: absolute;
    right: 0;
    z-index: 1;
}
.id {
    display: table;
}
.trans {
    background: 0 0;
    font-weight: 600;
    color: #696969;
    padding: 0;
}
.save-exit p {
    font-weight: 600;
    color: #ff00ea;
    float: right;
    position: absolute;
    bottom: 49px;
    font-size: 11px;
    right: 22px;
    top: 12px;
    cursor: pointer;
    height: 0;
}
button.copyall {
    border: none;
    width: auto;
    position: absolute;
    top: 20px;
    right: 0;
}
button.copyall:hover {
    background: 0 0;
    color: #fff;
}
.valuePerUnit {
    display: contents;
}
#male {
    width: 18px;
    margin-top: 5px;
}
.containerss {
    margin: 0;
    padding: 1px;
    padding-left: 7px;
}
.setradio {
    display: -webkit-flex;
    display: flex;
}
.spinner {
    margin: 0 auto;
    display: block;
    text-align: center;
}
span.againFile {
    margin-left: 16px;
    font-size: 13px;
    color: #a7a09a;
    margin-top: 21px;
}
button.signupm {
    position: absolute;
    top: 0;
    right: 6px;
    top: 6px;
    padding: 5px;
}
button.btn.btn-toogles.active.btn.btn-primary.brown {
    background: #b69783;
    border: 1px solid #000;
}
.colors {
    display: none;
}
.red {
    display: block;
}
input#maless {
    width: 31px;
    margin-top: 5px;
}
input#males {
    width: 54px;
    margin-top: 5px;
}
.setleft {
    position: relative;
    left: 33px;
    margin-bottom: 17px;
}
.switch-field {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 36px;
    overflow: hidden;
    margin: 0 auto;
}
.switch-field input {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
}
.switch-field label {
    background-color: transparent;
    color: #292929;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    padding: 6px 10px;
    margin-right: -1px;
    border: 1px solid #0f5238;
    border: 1px solid var(--secondary_btn-color);
    box-shadow: none;
    transition: all 0.1s ease-in-out;
    font-size: 14px;
    margin: 2px;
    border-radius: 0;
    font-weight: 600;
}
.switch-field label:hover {
    cursor: pointer;
}
/* .switch-field.nospace label.toogles {
    margin: 0;
} */
.switch-field input:checked + label {
    background-color: #bf73f3;
    background-color: var(--buttom_color);
    box-shadow: none;
    border: 1px solid #bf73f3;
    border: 1px solid var(--buttom_color);
    width: 100%;
    padding: 6px 10px;
}
.button_group.space-bottom .switch-field label {
    margin: 0;
}
.switch-field label:first-of-type {
    border-radius: 20px;
}
.switch-field label:last-of-type {
    border-radius: 20px;
}
label.toogles.actives, label.toogles1.actives {
    background-color: #bf73f3;
    background-color: var(--buttom_color);
    box-shadow: none;
    border: 1px solid #bf73f3;
    border: 1px solid var(--buttom_color);
    color: #ffffff;
}
label.Yes {
    background: #ff00ea;
    width: 88px;
    position: relative;
    right: 25px;
}
label.no {
    background: #b4c9d0;
    width: 88px;
}
label.Yes {
    background: #ff00ea;
    width: 88px;
    position: relative;
    right: 25px;
}
label.no {
    background: #b4c9d0;
    width: 88px;
}
label#show {
    width: 88px;
    position: relative;
    right: 20px;
}
label#hide {
    width: 88px;
}
div#hidemy {
    display: none;
}
div#showmy {
    display: none;
}
.flag-select__option {
    cursor: pointer;
    padding: 0;
    margin: 0;
    white-space: nowrap;
}
button#select_flag_button {
    width: 93%;
    padding: 0;
    font-size: 15px;
}
.flag-select {
    position: relative;
    display: inline-block;
    vertical-align: inherit;
    padding-bottom: 0;
    text-align: left;
    width: 100%;
    background: #f9f9f9;
}
button#select_flag_button:hover {
    background: 0 0;
    color: #000;
}
.flag-select__option__icon {
    width: 1.3em;
    height: 1.3em;
    position: relative;
    top: -2px;
}
button:focus {
    outline: 1px dotted;
    outline: 0;
}
.flag-select {
    margin-bottom: 15px;
    margin-top: 3px;
    padding: 4px 0;
}
.checkmystyle {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
}
.checkmystyle input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.checkmark.Box {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #0f5238;
    background-color: var(--secondary_btn-color);
    border-radius: 0;
}
.checkmystyle:hover input ~ .checkmark {
    background-color: #ccc;
}
.checkmystyle input:checked ~ .checkmark {
    background-color: #b69783;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.checkmystyle input:checked ~ .checkmark:after {
    display: block;
}
.checkmystyle .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
span.styletest {
    margin-left: 34px;
}
.flag-select__btn:after {
    position: absolute;
    right: 8px;
    top: 16px;
}
.flag-select__options {
    width: 100%;
}
#printDate {
    margin-left: 4px;
}
canvas {
    background: #f9f9f9;
}
button.btn.btn-default.button.clear {
    background: #ff00ea;
    color: #fff;
    margin: 22px 0 0;
}
.Other.box {
    display: none;
}
.None.box {
    display: none;
}
.btn_upload {
    cursor: pointer;
}
.yes {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    margin-top: 10px;
}
.btn_upload input {
    cursor: pointer;
    height: auto;
    position: absolute;
    -moz-opacity: 0;
    opacity: 0;
}
.it {
    margin-left: 0;
    font-size: 13px;
    color: #a7a09a;
}
input#removeImage2 {
    background: #5c99a7;
    padding: 0 0 0 6px;
    margin-left: 4px;
    margin-top: -2px;
}
.btn-rmv1 {
    display: none;
}
.btn-rmv2 {
    display: none;
}
.btn-rmv3 {
    display: none;
}
.btn-rmv4 {
    display: none;
}
.btn-rmv5 {
    display: none;
}
.rmv {
    cursor: pointer;
    color: #fff;
    border-radius: 0;
    border: 1px solid #fff;
    display: inline-block;
    background: red;
    margin: 0;
    position: absolute;
}
.rmv:hover {
    background: rgba(255, 0, 0, 0.5);
}
#ImgPreview2 {
    margin-top: 22px;
    width: 20%;
    height: auto;
}
#ImgPreview2 {
    margin-top: 22px;
    width: 20%;
    height: auto;
    margin-bottom: 42px;
    margin-left: 0;
}
.rmv {
    width: 23px;
    padding: 2px 10px 2px 6px;
    background: #b69783;
}
.spceimg {
    margin-top: 10px;
}
.ml-50 {
    margin-left: 50px;
}
ul.flag-select__options {
    padding: 5px;
    line-height: 28px;
}
.block #hidemy {
    display: block;
}
.fullinput.row .col-11 label {
    margin-top: 14px;
}
.fullspace.pandingfinsh .col-11 label {
    margin-top: 14px;
}
.yesnoNameerror {
    position: relative;
    width: 100%;
    bottom: 0;
}
input.numbers.setinput {
    margin-top: 7px;
}
.display {
    display: -webkit-flex;
    display: flex;
}
a.hyperLink1 {
    margin-top: -2px;
    margin-left: 10px;
    color: #b4947e;
    font-weight: 600;
}
.ui.compact.icon.button {
    padding: 6px 2px;
    width: 24px;
}
.ui.compact.icon.buttons .button {
    padding: 6px 2px;
    width: 24px;
}
.clndr-cell-disabled {
    cursor: default;
    background: #ff00ea;
    color: #fff;
    opacity: 1;
    text-align: center;
}
[role="button"] {
    text-align: center;
}
.ui.icon.input > i.icon:after {
    top: 28%;
}
.ui.icon.input > i.icon:before {
    top: 28%;
}
.ui.icon.input > input {
    background: #f9f9f9;
    border: none;
    border-radius: 0;
}
button#rfs-btn {
    background: #fff;
    border: none;
    border-radius: 5px;
    color: #76787e;
}
button#rfs-btn:hover {
    background: #fff;
    border: none;
    border-radius: 5px;
    color: #76787e;
}
i.icon.angle.double.right:before {
    margin-right: 6px;
}
i.icon.angle.double.left:before {
    margin-right: 22px;
}
.clndr-calendars-segment.clndr-floating {
    top: 35px;
}
.form select {
    font-weight: 200;
}
.button_group.space-bottom .step-change.active {
    background-color: #b69783;
    box-shadow: none;
    border: 1px solid #000;
}
.step-change {
    background: #b4c9d0;
    border: none;
}
.step-change.actives {
    background-color: #b69783;
    box-shadow: none;
    border: 1px solid #000;
}
.ui.icon.input > input {
    width: 537px;
}
.colors .col-11 label {
    margin-top: 14px;
}
span.error.file {
    bottom: 0;
    margin-left: 14px;
}
.numberSet.col-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 20%;
}
.numberSets.col-6 {
    -webkit-flex: 0 0 60%;
            flex: 0 0 60%;
    max-width: 55%;
}
.ui.icon.input > i.icon {
    top: 18px;
    height: auto;
}
.swal-icon--success__line--tip {
    width: 25px;
    left: 4px;
    top: 47px;
}
.swal-button {
    border: 1px solid #0f5238;
    border: 1px solid var(--secondary_btn-color);
    color: #000;
    box-shadow: none;
    border-radius: 0;
    cursor: pointer;
    background-color: transparent;
}
.swal-button:focus {
    outline: 0;
    box-shadow: none;
}
.swal-button:not([disabled]):hover {
    background-color: #0f5238;
    background-color: var(--secondary_btn-color);
}
.swal-text {
    color: rgb(111 111 111);
    font-weight: 100;
    font-size: 14px;
    font-family: revert;
}
.mmyset {
    position: relative;
}
.info.myset {
    position: absolute;
    top: 5px;
    left: 52%;
    cursor: pointer;
}
.deisablecrouse {
    cursor: not-allowed;
}
.error.postalError {
    bottom: 4px;
}
.ui.disabled.input {
    opacity: 1.45;
}
.ui.input input[disabled] {
    opacity: 1.45;
}
.swal-footer {
    text-align: center;
}
span.error.countryOfResidence {
    bottom: 6px;
}
div#result {
    color: #b00020;
    color: var(--error_color);
    position: relative;
    bottom: 12px;
}
span.error.takepics {
    text-align: center;
    left: 38%;
    bottom: 4px;
}
.info {
    float: right;
    position: absolute;
    bottom: 49px;
    font-size: 11px;
    right: 5px;
    top: 14px;
    cursor: pointer;
}
#root > div > div > div > div > div:nth-child(10) > div:nth-child(2) > div > div.flag-dropdown.open > ul > li:nth-child(88) span.country-name {
    position: relative;
    top: -32px;
}
#root > div > div > div > div > div:nth-child(10) > div:nth-child(2) > div > div.flag-dropdown.open > ul > li:nth-child(88) span.dial-code {
    position: relative;
    top: -32px;
}
#root > div > div > div > div > div:nth-child(10) > div:nth-child(2) > div > div.flag-dropdown.open > ul > li:nth-child(88) li.country {
    height: 30px;
}
span.country-name {
    font-size: 12px;
}
span.dial-code {
    font-size: 12px;
}
.error-list {
    color: rgb(111 111 111);
    font-weight: 100;
    font-size: 14px;
    font-family: revert;
    padding: 0;
}
.error-list a {
    color: rgb(111 111 111);
    font-weight: 100;
    font-size: 14px;
    font-family: revert;
}
.checkbox-my.left-more {
    margin-left: 42px;
}
span.error.setSelfieImage {
    text-align: center;
    bottom: -8px;
}
.css-yk16xz-control {
    background: #f9f9f9;
    height: 40px;
    padding: 0;
    margin-bottom: 42px;
    border: none;
}
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
    width: 222px;
}
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    width: 222px;
}
input#radio-one2 {
    position: absolute;
}
.switch-fieldd.nospacee {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
.switch-fieldd.nospacee label {
    color: #000;
    padding: 5px;
    font-size: 14px;
    border-radius: 0;
    font-weight: 300;
}
.button_group.space-bottom .step-changes.active {
    background-color: #b69783;
    box-shadow: none;
    border: 1px solid #000;
}
input#radio-three {
    z-index: 999949;
    position: absolute;
    opacity: 0;
    left: -1px;
    width: 187px;
    margin-left: 0;
    height: 52px;
}
input#radio-four {
    z-index: 999949;
    position: absolute;
    opacity: 0;
    left: -1px;
    width: 187px;
    margin-left: 0;
    height: 52px;
}
.switch-fieldd.nospacee .step-change {
    width: 102px;
    cursor: pointer;
}
.switch-fieldd .step-change {
    color: #fff;
    padding: 11px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    width: 162px;
    cursor: pointer;
}
.spcewidths .switch-fieldd .step-change {
    width: 262px;
    cursor: pointer;
}
.red-dom .joint-acc {
    z-index: 999999;
    position: absolute;
    opacity: 0;
    width: 100%;
    margin: 0;
    height: 100%;
    cursor: pointer;
}
.red-dom {
    position: relative;
}
.spcewidths label.step-change {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    padding: 9px;
}
.red-dom label.step-change {
    margin-bottom: 0;
}
.spcewidths .step-change.active {
    background: #ff00ea;
    border: 1px solid #000;
}
.switch-fieldd.nospacee.wids .step-change {
    width: 71px;
    cursor: pointer;
}
.switch-fieldd.nospacee.wids .step-change.active {
    background: #ff00ea;
}
input:-internal-autofill-selected {
    -webkit-appearance: menulist-button;
            appearance: menulist-button;
    background-color: #f9f9f9;
    background-image: none;
    color: -internal-light-dark(#a2a2a2, #fff);
    color: #a2a2a2;
}
input:-webkit-autofill {
    -webkit-text-fill-color: #a2a2a2;
    -webkit-box-shadow: 0 0 0 1000px #f9f9f9 inset;
}
input:-webkit-autofill:active {
    -webkit-text-fill-color: #a2a2a2;
    -webkit-box-shadow: 0 0 0 1000px #f9f9f9 inset;
}
input:-webkit-autofill:focus {
    -webkit-text-fill-color: #a2a2a2;
    -webkit-box-shadow: 0 0 0 1000px #f9f9f9 inset;
}
input:-webkit-autofill:hover {
    -webkit-text-fill-color: #a2a2a2;
    -webkit-box-shadow: 0 0 0 1000px #f9f9f9 inset;
}
.ui.icon.input > input:-webkit-autofill {
    -webkit-text-fill-color: #a2a2a2;
    -webkit-box-shadow: 0 0 0 1000px #f9f9f9 inset;
}
.ui.icon.input > input:-webkit-autofill:active {
    -webkit-text-fill-color: #a2a2a2;
    -webkit-box-shadow: 0 0 0 1000px #f9f9f9 inset;
}
.ui.icon.input > input:-webkit-autofill:focus {
    -webkit-text-fill-color: #a2a2a2;
    -webkit-box-shadow: 0 0 0 1000px #f9f9f9 inset;
}
.ui.icon.input > input:-webkit-autofill:hover {
    -webkit-text-fill-color: #a2a2a2;
    -webkit-box-shadow: 0 0 0 1000px #f9f9f9 inset;
}
input {
    color: #76787e;
}
.button_group.irdspace input {
    color: #fff;
}
.mb-custom {
    margin-bottom: 0;
}
.dr-zero {
    float: right;
}
.button_group.irdspace input.toogles {
    width: auto;
    padding: 8px 16px;
    line-height: 1.2;
    background: #999a99;
}
.rmv {
    color: #fff;
}
.position-na {
    bottom: 0;
}
.error.countryOfBirth {
    bottom: 10px;
}
.error.postals {
    bottom: 0;
}
.infos {
    top: -5px;
    position: relative;
}
ul.d-none.d-md-block.navbar-nav {
    position: absolute;
    top: 14px;
    z-index: 1;
}
.checkbox-my span.error {
    margin-left: 36px;
}
input.mouse.otherInput {
    margin-bottom: 17px;
    margin-top: -8px;
}
span.error.investmentProductExpOther {
    margin-left: 0;
    bottom: 8px;
}
.pl-34 {
    padding-left: 34px;
}
ul.welcome-space li {
    margin-bottom: 0.5rem;
}
span.error.postalad {
    bottom: -3px;
}
.spceimg input {
    width: 23px;
}
.space-btn {
    display: -webkit-flex;
    display: flex;
}
.space-btn label {
    width: 154px;
    margin: 5px;
    padding: 10px;
}
.ful_width input {
    width: 100%;
}
button.btncolor {
    background: #999a99;
    color: #fff;
    border: none;
    width: 166px;
    padding: 0.5rem 5px;
    border: 1px solid #999a99;
}
label.toogles.actives.comp_dir_btn {
    background: #999a99;
    color: #fff;
    border: none;
    width: 166px;
    padding: 0.5rem 5px;
    border: 1px solid #999a99;
}
label.toogles.actives.comp_dir_btn {
    padding: 0.7rem 5px;
}
button.btncolor:hover {
    background: #ff00ea;
    color: #fff;
    border: 1px solid #000;
}
label.toogles.actives.comp_dir_btn:hover {
    background: #ff00ea;
    color: #fff;
    border: 1px solid #000;
}
.custom-margin {
    margin-left: 48px;
    margin-top: 0;
}
.b-0 {
    bottom: 0;
}
.b-5 {
    bottom: 5px;
}
.error_text {
    bottom: 0;
    text-align: center;
    display: block;
}
.swal-content__div {
    color: rgb(111 111 111);
    font-weight: 100;
    font-size: 14px;
}
.minusten {
    margin-top: -7px;
}
.width-100 label {
    width: 100%;
}
.switch-field.margin-btn label {
    margin: 0;
}
.onlyshare.row:nth-child(odd) {
    display: none;
}
.deleteshow {
    display: none;
}
.fullinput.onlyshare.row.main {
    display: -webkit-flex;
    display: flex;
}
.fullinput.onlydirector.row.main {
    display: -webkit-flex;
    display: flex;
}
label.heading-margin {
    margin-bottom: 32px;
    margin-top: 6px;
}
:is(button.coffee-colortwo, label.toogles.actives.comp_dir_del_btn) img {
    max-width: 20px;
}
button.coffee-colortwo {
    background: #b69783;
    width: 47px;
    height: 42px;
    padding: 0;
    border: 2px solid #c7b0a0;
}
label.toogles.actives.comp_dir_del_btn {
    background: #b69783;
    width: 47px;
    height: 42px;
    padding: 0;
    border: 2px solid #c7b0a0;
}
label.toogles.actives.comp_dir_del_btn {
    padding: 8px;
}
button.next.setwidht.width_oneeighteen {
    width: 118px;
}
.policy_font {
    font-size: 13px;
}
.t-55 {
    top: 55px;
}
@media screen and (min-width: 786px) {
    .m-signature-pad--body canvas {
        width: 100%;
    }
    video {
        width: 100%;
    }
}
.hideField {
    display: none;
}
@media screen and (min-width: 1400px) {
    .usual.back-color.terms_cond {
        height: 199.9px;
    }
}
button.ant-btn.uplodeId {
    width: 183px;
    border: none;
    margin: 9px 0;
    text-align: left;
    border-radius: 5px;
}
button.next.grad_btn:hover {
    /* background-image: linear-gradient(to right, #4989aa, #4f9092);
    background-image: linear-gradient(to right, #4989aa, #4f9092); */
    /* box-shadow: inset -1px 4px 2px 2px #49585b; */
    background-color: #cc8ff5;
}
button.preview.trans_btn:hover {
    background-color: transparent;
    background-color: initial;
    border: 2px solid #0a3826!important;
    border: 2px solid #0a3826!important;
    border: 2px solid var(--sec_hover_color)!important;
    color: #0a3826;
    color: #0a3826;
    color: var(--sec_hover_color);
    display: table;
    padding: 10px 15px;
}

button.preview.trans_btn {
    position: relative;
}
.grad_bt {
    /* background-image: linear-gradient(to right, #2a6481, #85c3c5); */
    border: none;
    padding: 10px 15px;
    color: #fff;
    display: table;
    margin-left: auto;
}
.grad_btn:hover {
    /* background-image: linear-gradient(to left, #2a6481, #85c3c5); */
    background-color: #cc8ff5;
    border: none;
    padding: 10px 15px;
    color: #fff;
    display: table;
    margin-left: auto;
}
.swal2-content input[type="checkbox"] {
    display: none;
}
.swal2-styled.swal2-confirm {
    border: 2px solid #0f5238;
    border: 2px solid var(--secondary_btn-color);
    border-radius: 0.25em;
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
    background: initial;
    background-color: transparent;
    color: #000;
    font-size: 1em;
}
.swal2-actions:not(.swal2-loading) .swal2-styled:active {
    background-image: none;
}
.swal2-styled:not([disabled]):hover {
    background-color: #0f5238;
    background-color: var(--secondary_btn-color);
}
.swal2-styled:focus {
    outline: 0;
    box-shadow: none;
}
a {
    color: #0f5238;
    color: var(--secondary_btn-color);
    font-weight: 600;
}
.swal2-styled.swal2-confirm {
    border: 2px solid #0f5238;
    border: 2px solid var(--secondary_btn-color);
    border-radius: 0.25em;
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
    background: initial;
    background-color: transparent;
    color: #000;
    font-size: 1em;
    width: 128px;
}
.swal2-actions:not(.swal2-loading) .swal2-styled:active {
    background-image: none;
}
.swal2-styled:focus {
    outline: 0;
    box-shadow: none;
}
.react-tel-input .flag-dropdown {
    background: #fff;
    border-radius: 5px;
    border: none;
}
.swal2-styled.swal2-cancel {
    background-color: #2b4859;
}
.swal2-styled.swal2-cancel:hover {
    background-color: #fff;
    border: 2px solid #0f5238;
    border: 2px solid var(--secondary_btn-color);
    border-radius: 0.25em;
    color: #000;
}
.digit {
    display: inline-block;
    margin-right: 10px;
}
ul.information {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
li.list_style {
    display: -webkit-inline-flex;
    display: inline-flex;
}
.eerror {
    color: #0f5238;
    color: var(--secondary_btn-color);
    font-size: 13px;
    font-weight: 600;
}
button.ui.compact.fluid.button.clndr-button-today {
    display: none;
}
.swal2-container.swal2-top-end.swal2-backdrop-show {
    display: none;
}
.numberSet.mt-1.mb-2.col-xs-12.col-md-3.col-12 .react-tel-input {
    margin-top: 9px;
}
img.triger_image {
    height: 20px;
    margin: 0 5px;
}
.mmmmm {
    display: -webkit-inline-flex;
    display: inline-flex;
}
.popover {
    max-width: 400px;
}
button.swal2-confirm.swal2-styled {
    background: #0f5238;
    background: var(--secondary_btn-color);
    color: #fff;
}
button.swal2-cancel {
    padding: 0.625em 1.1em !important;
    background: 0 0 !important;
    color: red !important;
    border: 2px solid red !important;
}

button.clndr-cell {
    width: 100%;
}
.custom_datepiker .clndr-cell-disabled {

    margin-top: 7px;
}

/* #stuff {
    opacity: 0.0;
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -ms-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
    color: red;
}
#hover {
    width:80px;
    height:20px;
    background-color:green;
    margin-bottom:15px;
}
#hover:hover + #stuff {
    opacity: 1.0;
} */
.img_circle{
    max-width: 900px !important;
}
.img_circle .row{
    -webkit-justify-content: center !important;
            justify-content: center !important;
}
.img_circle .radio{
    padding-left: 0;
}
@media screen and (max-width:991px) {

    .img_circle .container{
    max-width: 790px !important;
}
.img_circle .flex-th div{
    width: 150px;
    height: 150px;
}
}
@media screen and (max-width:767px){
    .img_circle .reduse_mar {margin-bottom: 0 !important;}
    
}
.footer button.preview.trans_btn:focus {
    background-color: #ccf3ec!important;
    border-color: #1d5d45!important;
    border-color: #1d5d45!important;
    border-color: var(--sec_focus_color)!important;
    color: #1d5d45!important;
    color: #1d5d45!important;
    color: var(--sec_focus_color)!important;
    box-shadow: none!important;
}
.footer .btn-primary.focus, .footer .btn-primary:focus {
    background-color: #a061cc!important;
    background-color: #a061cc!important;
    background-color: var(--focus_color)!important;
    border-color: #a061cc!important;
    border-color: #a061cc!important;
    border-color: var(--focus_color)!important;
    box-shadow: none!important;
}
.clndr-control .icon {
    color: #0f5238;
    color: #0f5238;
    color: var(--secondary_btn-color);
}
.custom_datepiker .ui.compact.icon.button {
    box-shadow: inset 0 0 0 1px #0f5238;
    box-shadow: 0 0 0 1px #0f5238 inset;
    box-shadow: 0 0 0 1px var(--secondary_btn-color) inset;
}
.swal2-container a:hover{
    color: #0a3826;
    color: var(--sec_hover_color);
}

/* check-box css start */
.check_YN_Bank {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    max-width: 120px;
    border: 1px solid #1d5d45;
    border: 1px solid var(--sec_focus_color);
    border-radius: 30px;
    padding: 2px;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.check_YN_Bank label#show {
    width: 50%;
    right: 0;
    margin-right: 2px;
}
.check_YN_Bank label#hide {
    width: 50%;
    margin-left: 2px;
}
/* check-box css end */
.Frequency {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}
.Frequency input {
    width: 47.5%;
}
.provide_tax .button_group {
    margin-left: 0;
}

span.yes_No {
    margin-top: 16px;
}
.swal2-loader {
    border-color: #0f5238 transparent #0f5238 transparent !important;
    border-color: var(--secondary_btn-color) transparent var(--secondary_btn-color) transparent !important;
}

.clndr-cell:hover{
    background-color: #0a3826 !important;
    background-color: var(--sec_hover_color) !important;
    color: #ffffff !important;
}
/* .clndr-cell{
    background-color: var(--sec_hover_color) !important;
    color: #ffffff !important;
} */
 
.clndr-cell-selected{
    background-color: #0f5238 !important;
    background-color: var(--secondary_btn-color) !important;
    color: #ffffff !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    appearance: none;
    margin: 0; 
}
.eerror span{
    color: #0f5238;
    color: var(--secondary_btn-color);
}
 
.popupstyle{
    width: 100% !important;
    max-width: 700px !important;
    text-align: right !important;
    text-align: justify !important;
    font-size: 14px !important;
 }
 .textpopup{
    text-align: justify !important;

 }
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body,
html {
    font-family: 'Plus Jakarta Sans';
}
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Plus Jakarta Sans';
}
body {
    background: url(/static/media/bg.f741054b.webp) center center;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}
:root{
    --buttom_color:#bf73f3;
    --button_hover_color:#cc8ff5;
    --focus_color:#a061cc;
    --primery_disable_btn:#e7cafa;
    --secondary_btn-color:#0f5238;
    --sec_hover_color:#0a3826;
    --sec_focus_color:#1d5d45;
    --error_color:#b00020;
    -o-border_color:#bbefe5;
}
.body_content {
    background: #f0f0f0;
}
.headerlogo {
    /* background: #477894;
    padding: 40px 50px; */
    background-image: url(/static/media/bgheader.bcbe1c1e.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 50px;
    /* display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; */

}
.headerlogo img {
    max-width: 200px;
    margin: 0 auto;
    /* order: 2; */
    display: table;
    /* width: 30%; */
}
.margin-25 {
    padding: 25px 40px;
}
.margin-50 {
    padding: 50px 40px;
}
.headercontent {
    margin-top: 25px;
    /* order: 1;
    width: 70%; */
}
.headercontent h3 {
    color: #fff;
    margin-bottom: 0;
    text-align: center;
}
label {
    color: #292929;
    font-size: 16px;
    font-weight: 600;
}
p {
    color: #76787e;
    font-size: 16px;
    margin-bottom: 0;
}
.grad_btn,
.grad_btn:hover {
    /* background-image: linear-gradient(to right, #2a6481, #85c3c5); */
    border: none;
    background-color: #bf73f3;
    padding: 10px 15px;
    color: #fff;
    display: table;
    margin-left: auto;
}
button.trans_btn,
button.trans_btn:hover {
    background: 0 0;
    /* border: none; */
    color: #0f5238;
    padding: 10px 0;
}
button.border_trans_btn {
    background: 0 0;
    border: 1.5px solid #0f5238;
    color: #0f5238;
    color: var(--secondary_btn-color);
    padding: 8px 15px;
    max-width: 153px;
    width: 154px;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 600;
}
.btn_icon {
    margin-left: 15px;
    margin-top: -5px;
    cursor: pointer;
}
.radio_select .btn_icon {
    margin-top: -15px;
}
.arrow_size {
    max-width: 15px;
    margin-left: 10px;
    margin-top: -4px;
}
.trans_btn img.arrow_size {
    margin-left: 0;
    margin-right: 7px;
}
.dot-icon {
    margin-left: 25px;
}
.dot-icon p:before {
    position: relative;
    content: "";
    width: 16px;
    height: 16px;
    background: #7bc589;
    display: block;
    top: 20px;
    left: -25px;
    border-radius: 50%;
}
.footer {
    margin-top: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
input,
select,
textarea {
    width: 100%;
}
.oneeightyrotate {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
.radio {
    display: block;
    position: relative;
    padding-left: 5px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    color: #76787e;
    font-weight: 400;
}
.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #76787e;
    border-radius: 50%;
}
.radio:hover input ~ .checkmark {
    background-color: #ccc;
}
.radio input:checked ~ .checkmark {
    background-color: transparent;
    border: 2px solid #0f5238;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.radio input:checked ~ .checkmark:after {
    display: block;
}
.radio .checkmark:after {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #0f5238;
}
.message_text {
    height: 192px;
    color: #76787e;
    padding: 10px 15px;
    border-radius: 8px;
    overflow-x: auto;
}
.message_text ul {
    list-style: none;
    padding: 0;
}
.submit_text {
    text-align: center;
    max-width: 515px;
    margin: auto;
}
.custom_datepiker .field {
    width: 100%;
}
.custom_datepiker .ui.segment {
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
    margin: 1rem 0;
    padding: 1em;
    border-radius: 0.28571429rem;
    border: 1px solid rgba(34, 36, 38, 0.15);
}
.custom_datepiker .ui.icon.input input {
    background: #fff;
    border: none;
    font-size: 15px;
    border-radius: 0;
    padding: 8px 11px;
    width: 100%;
    border-radius: 5px;
}
.custom_datepiker .ui.segment {
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
    margin: 1rem 0;
    padding: 1em;
    border-radius: 0.28571429rem;
    border: 1px solid rgba(34, 36, 38, 0.15);
}
.custom_datepiker .ui.basic.button,
.custom_datepiker .ui.basic.buttons .button {
    background: 0 0;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    border-radius: 0.28571429rem;
    text-transform: none;
    text-shadow: none;
    box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
}
.custom_datepiker .ui.compact.icon.button,
.custom_datepiker .ui.compact.icon.buttons .button {
    padding: 6px 2px;
    width: 24px;
}
.custom_datepiker .ui.button {
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    background: #e0e1e2;
    color: rgba(0, 0, 0, 0.6);
    font-family: 'Plus Jakarta Sans';
    margin: 0 0.25em 0 0;
    padding: 0.78571429em 1.5em;
    text-transform: none;
    text-shadow: none;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    -webkit-user-select: none;
    user-select: none;
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;
    will-change: "";
    -webkit-tap-highlight-color: transparent;
}
.custom_datepiker .ui.icon.button > .icon,
.custom_datepiker .ui.icon.buttons .button > .icon {
    opacity: 0.9;
    margin: 0;
    vertical-align: top;
}
.custom_datepiker .ui.button > .icon:not(.button) {
    height: 0.85714286em;
    opacity: 0.8;
    margin: 0 0.42857143em 0 -0.21428571em;
    transition: opacity 0.1s ease;
    vertical-align: "";
    color: "";
}
select {
    -webkit-appearance: none;
    appearance: none;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAMAAABlXnzoAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAEVQTFRFD1I4D1I4D1I4D1I4D1I4AAAAD1I4D1I4D1I4D1I4D1I4D1I4D1I4D1I4D1I4D1I4D1I4D1I4D1I4D1I4D1I4D1I4D1I4CNQulAAAABd0Uk5TtP8l+fgAjA7nXwHHN/6gGPFzBdnaBkvfe1GMAAAAT0lEQVR4nE3HRxKAIBAF0Rn0k6OB+x9VkULoTdcj4iUisU3tgiAnJQhKD2n1EmbQoNG6Lmc/woem4NGJ2BgxmDJzTj9RmAsmj/O6F6LW/gfLxwMm//ZQXQAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: center;
}
.custom_datepiker i.icon {
    display: inline-block;
    opacity: 1;
    margin: 0 0.25rem 0 0;
    width: 1.18em;
    height: 1em;
    font-family: Icons;
    font-style: normal;
    font-weight: 400;
    text-decoration: inherit;
    text-align: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.custom_datepiker i.icon.angle.double.left:before {
    margin-right: 22px;
    content: "<<";
}
.custom_datepiker i.icon.angle.left:before {
    content: "<";
}
.custom_datepiker i.icon.angle.right:before {
    content: ">";
}
.custom_datepiker i.icon.angle.double.right:before {
    margin-right: 6px;
    content: ">>";
}
.custom_datepiker .clndr-control .left {
    padding-left: 5px;
}
.custom_datepiker .clndr-control .right {
    padding-left: 5px;
}
.custom_datepiker .clndr-cell-disabled {
    cursor: default;
    opacity: 0.45;
    background: #0f5238;
    background: var(--secondary_btn-color);
    color: #fff;
    opacity: 1;
    text-align: center;
}
.custom_datepiker .ui.fluid.button {
    display: block;
}
.custom_datepiker .ui.compact.button,
.custom_datepiker .ui.compact.buttons .button {
    width: 100%;
}
input#date::-webkit-input-placeholder {
    text-transform: lowercase;
}
input#date::placeholder {
    text-transform: lowercase;
}
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background: #0f5238;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #0a3826;
    background: var(--sec_hover_color);
}
.ReactFlagsSelect-module_selectBtn__19wW7:after {
    border-top: 12px solid #0f5238;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 0;
}
.ui.icon.input input {
    text-transform: lowercase;
}
.ui.icon.input i.calendar.link.icon {
    display: none;
}
span.error.filerror {
    margin-top: 20px;
    display: table;
}
span.msg-show {
    color: #0f5238;
    position: relative;
    bottom: 22px;
    font-size: 13px;
    font-weight: 600;
}
span.msg-shown {
    color: #b00020;
    color: var(--error_color);
    position: relative;
    bottom: 22px;
    font-size: 13px;
    font-weight: 600;
}
button.border_trans_btn.disable {
    pointer-events: none;
}
.swal2-styled.swal2-confirm {
    width: auto;
}
.img-hover-zoom {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: relative;
    margin-right: 20px;
}
.d-flex.justify-content-center.align-items-centers {
    position: relative;
    top: 5px;
}
p.text-white.mb-0:before {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    opacity: 0.4;
    background-color: #000;
}
p.text-white.mb-0.text {
    bottom: 25px;
}
p.text-white.mb-0 {
    position: absolute;
    bottom: 48px;
    right: -14px;
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 100%;
}
.img-hover-zoom:hover img.btn_icon {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 2px 2px 2px #4b4b4b;
    opacity: 1;
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
}
.text-bg {
    position: relative;
}
.flex-th.cons div {
    background: url(/static/media/Conservative.dcd95286.webp);
    background-size: cover;
    background-repeat: no-repeat;
}
.flex-th.First div {
    background: url("/static/media/First Home Buyer Strategy.9bc7541b.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.flex-th.Balance div {
    background: url(/static/media/Balanced.d8d90216.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.flex-th.Growth div {
    background: url("/static/media/Growth Fund (2).d1e55818.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
}
.flex-th.ClimateGrowth div {
    background: url(/static/media/ClimateGrowthStrategy.f61f108b.webp);
    /* background-size: cover;
    background-repeat: no-repeat;
    background-position: right; */
    background-position: 24%;
    background-repeat: no-repeat;
    background-size: cover;
}
.flex-th.LegacyPath div {
    background: url(/static/media/RetirementPlusStrategy.525dbf20.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
}
.flex-th.LegacyPath1 div {
    background: url(/static/media/LiquidFund.f11f1676.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
}
.flex-th div {
    width: 180px;
    height: 180px;
    background-size: cover;
    margin: 0;
    text-align: center;
    border-radius: 175px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    overflow: hidden;
    background: url("/static/media/Growth Fund (2).d1e55818.webp");
    background-size: 100%;
    background: #fff;
    position: relative;
}
.flex-th div:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    background: #0006;
}
.flex-th {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 0;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.flex-th {
    margin: auto;
}
.flex-th p {
    color: #fff;
    padding: 10px 23px;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 14px;
    position: relative;
    z-index: 9;
}
.flex-th div span {
    height: 92px;
    display: table;
    width: 100%;
    opacity: 0.4;
}
.flex-th div:before {
    background: #fff;
    height: 100%;
    position: absolute;
    content: "";
    width: 100%;
    opacity: 0.3;
}
.flex-th div:hover:before {
    opacity: 0;
}
.flex-th div:hover {
    transition: 0.3s;
    opacity: 1;
}
.margin-24 {
    padding: 21px 26px;
}
.btn_icon {
    margin-left: 15px;
    margin-top: -5px;
    cursor: pointer;
}
.current {
    border: 6px solid #0f5238;
    transition: 0.3s;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    background: 0 0;
}
.current span,
.current:before {
    background: 0 0;
}
.headerlogo.side {
    padding: 40px 40px;
}
.pass-wrapper {
    position: relative;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 14px;
}
.eye-password {
    position: absolute;
    top: 34px;
    right: 8%;
    font-size: 20px;
}
i:hover {
    color: #000;
    cursor: pointer;
}
.fa-eye:before {
    content: "\F06E";
    top: 5px;
    position: relative;
    color: #0f5238;
}
.irdNumbers {
    color: #b00020;
    color: var(--error_color);
}
.custom_datepiker button.ui.compact.fluid.disabled.button.clndr-button-today {
    display: none;
}
@media only screen and (max-width: 768px) {
    .margin-25 {
        padding: 25px 0;
    }
    .form .react-tel-input .form-control {
        width: 100%;
    }
    /* .headerlogo img {
        order: 1;
        width: auto;
    } */
    
    /* .headercontent {
        order: 2;
        width: 100%;
    } */
    /* .headercontent h3{
        text-align: center;
    }
    .headerlogo {
        justify-content: center;
    } */
}
.loaderemail {
    border: 3px solid #ccc;
    border-radius: 50%;
    border-top: 3px solid #0f5238;
    width: 14px;
    height: 14px;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.mainloader_div {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
i.far.fa-check-circle.color {
    color: #0f5238;
}
body.swal2-shown.swal2-height-auto{
    height: 100%;
}

.main_conatainer {
    /* height: 100vh; */
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
@media screen and (max-width:767px) {
    .main_conatainer {
         height: auto;
       
    }
}
.link-text{
  color: #0f5238;
  color: var(--secondary_btn-color);

}
.link-text:hover{
  color: #0f5238 !important;
  color: var(--secondary_btn-color) !important;

}
